import { currentProjectName, ProjectName } from '@/config';
import { BreakPoint } from '@/hooks/useBreakPoint/consts';
import useFeatureToggle from '@/hooks/useFeatureToggle/useFeatureToggle';
import { HEADER_LOGO_LINKS } from '@/hooks/useHeader/config';
import {
  MAIN_HEADER_COLLAPSIBLE,
  MAIN_HEADER_MOBILE_COLLAPSIBLE,
  MAIN_HEADER_SEARCH_COLLAPSIBLE,
  MAIN_HEADER_SESSION_COLLAPSIBLE,
  PAGE_HEADER_COLLAPSIBLE,
} from '@/hooks/useHeader/consts';
import useHeader from '@/hooks/useHeader/useHeader';
import useIsMobile from '@/hooks/useIsMobile/useIsMobile';
import useMeasure from '@/hooks/useMeasure/useMeasure';
import { useSafeLayoutEffect } from '@/hooks/useSafeLayoutEffect/useSafeLayoutEffect';
import useScroll from '@/hooks/useScroll/useScroll';
import searchConfig from '@/page-templates/SearchPage/consts';
import clsx from 'clsx';
import dynamic from 'next/dynamic';
import { CSSProperties, useCallback, useRef, useState } from 'react';
import CareerLogo from '../CareerLogo/CareerLogo';
import Link from '../Link/Link';
import ProjectLogo from '../ProjectLogo/ProjectLogo';
import SmallELogo from '../SmallELogo/SmallELogo';
import { HEADER_LOGO_HEIGHTS, HEADER_SHORT_LOGO_VARIANTS } from './consts';
import LinkAddon from './LinkAddon/LinkAddon';
import MainHeader from './MainHeader/MainHeader';
import styles from './NewHeader.module.scss';
import PreHeader from './PreHeader/PreHeader';
import SearchAddon from './SearchAddon/SearchAddon';
import { NewHeaderProps } from './types';

const SessionAddon = dynamic(() => import('./SessionAddon/SessionAddon'), {
  ssr: false,
});

const NewHeader = ({
  hasPageHeader,
  hasPreHeader = true,
  isFullWidth = false,
  isInverted = false,
  isSticky: _isSticky = true,
  renderMainHeaderAddons,
  renderPageHeader,
  variant = currentProjectName,
}: NewHeaderProps) => {
  const isSticky =
    typeof window !== 'undefined' && window.USE_MAGNOLIA_RENDERER
      ? false
      : _isSticky;

  const [documentEndReached, setDocumentEndReached] = useState(false);

  const outsideRef = useRef<HTMLDivElement>(null);

  const [stickyAt, setStickyAt] = useState(-1);
  const header = useHeader({ clickOutsideRef: outsideRef, variant });
  const isMobile = useIsMobile(
    variant === 'eng' ? BreakPoint.XL : BreakPoint.LG
  );
  const isXsBetween = useIsMobile(375);
  const { featureToggles } = useFeatureToggle();

  const headerRef = useRef<HTMLElement>(null);
  const mainHeaderRef = useRef<HTMLElement>(null);
  const pageHeaderRef = useRef<HTMLElement>(null);

  const headerMeasurement = useMeasure(headerRef, true);
  const mainHeaderMeasurement = useMeasure(mainHeaderRef, true);
  const pageHeaderMeasurement = useMeasure(pageHeaderRef, true);

  useSafeLayoutEffect(() => {
    if (isSticky && headerRef.current) {
      const headerBounds = headerRef.current.getBoundingClientRect();
      const stickyRef = hasPageHeader ? pageHeaderRef : mainHeaderRef;

      if (stickyRef.current) {
        const elementBounds = stickyRef.current.getBoundingClientRect();

        setStickyAt(elementBounds.top - headerBounds.top);
      }
    }
  }, [hasPageHeader, headerRef, isSticky, mainHeaderRef, pageHeaderRef]);

  useSafeLayoutEffect(() => {
    if (isSticky) {
      setStickyAt(
        (hasPageHeader
          ? pageHeaderMeasurement.top
          : mainHeaderMeasurement.top) - headerMeasurement.top
      );
    }
  }, [
    hasPageHeader,
    headerMeasurement,
    isSticky,
    mainHeaderMeasurement,
    pageHeaderMeasurement,
  ]);

  const handleScroll = useCallback(() => {
    if (typeof window !== 'undefined' && window.USE_MAGNOLIA_RENDERER) {
      return;
    }

    if (!isMobile) {
      return;
    }

    const scrollPosition = window.scrollY + window.innerHeight;
    if (scrollPosition >= document.body.offsetHeight && !documentEndReached) {
      setDocumentEndReached(true);
    } else if (
      scrollPosition < document.body.offsetHeight &&
      documentEndReached
    ) {
      setDocumentEndReached(false);
    }
  }, [documentEndReached, isMobile]);

  useScroll(handleScroll, { passive: true });

  const hasLogin = ['enercity', 'enercity-gwk'].includes(variant);
  const hasSearch =
    searchConfig.searchEnabled &&
    (!searchConfig.searchRequiresFeatureToggle || featureToggles.USE_SEARCH);
  const hasShortLogo =
    isXsBetween && HEADER_SHORT_LOGO_VARIANTS.includes(variant);

  const project =
    variant !== 'career' &&
    (variant.startsWith('enercity') ? 'enercity' : (variant as ProjectName));

  const projectLogo = (
    <Link
      className={styles.logo}
      title="Zur Startseite"
      to={HEADER_LOGO_LINKS[variant]}
    >
      {project &&
        (hasShortLogo ? (
          <SmallELogo height={26} solidFill={isInverted} />
        ) : (
          <ProjectLogo
            height={
              HEADER_LOGO_HEIGHTS[project][isMobile ? 'mobile' : 'desktop']
            }
            project={project}
            solidFill={isInverted && !header.mainHeaderShouldHaveBackground}
          />
        ))}
    </Link>
  );

  const style = {
    '--header-sticky-y': `-${stickyAt}px`,
  } as CSSProperties;

  return (
    <header
      className={clsx(styles.base, {
        [styles.isFixed]: isSticky && documentEndReached,
        [styles.isFullWidth]: isFullWidth,
        [styles.isInverted]: isInverted,
        [styles.isSticky]: isSticky,
      })}
      ref={headerRef}
      style={style}
    >
      <div className={styles.clickOutsideHandler} ref={outsideRef} />

      {hasPreHeader && (
        <PreHeader
          audiences={header.audiences}
          isFullWidth={isFullWidth}
          isInverted={isInverted}
          links={header.preHeaderLinks}
        />
      )}

      <MainHeader
        audiences={header.audiences}
        hasActiveCollapsible={
          header.activeCollapsible !== null &&
          [MAIN_HEADER_COLLAPSIBLE, MAIN_HEADER_MOBILE_COLLAPSIBLE].includes(
            header.activeCollapsible
          )
        }
        isFullWidth={isFullWidth}
        items={header.mainHeaderItems}
        layout={variant === 'career' ? 'career' : 'default'}
        logoSlot={variant === 'career' ? <CareerLogo /> : projectLogo}
        menuOffset={hasPageHeader ? 64 : 0}
        onCollapsibleToggle={header.handleCollapsibleToggle}
        preHeaderLinks={header.preHeaderLinks}
        ref={mainHeaderRef}
        renderAddons={({
          isInverted,
          isTranslucent,
          mobileContentOffset,
          shouldHideContent,
        }) => (
          <>
            {header.mainHeaderLinks.map((link) => (
              <LinkAddon
                icon={link.icon ?? 'action/external-link'}
                isHidden={shouldHideContent}
                isInternalUrl={link.isInternalUrl}
                isInverted={isInverted}
                title={link.title}
                url={link.url}
                key={link.title}
              />
            ))}

            {hasSearch && (
              <SearchAddon
                isCollapsibleActive={
                  header.activeCollapsible === MAIN_HEADER_SEARCH_COLLAPSIBLE
                }
                isHidden={shouldHideContent}
                isInverted={isInverted}
                onCollapsibleToggle={header.handleCollapsibleToggle}
                quickLinks={searchConfig.quicklinks}
                suggestionsBoxMobileOffset={
                  isTranslucent ? 0 : mobileContentOffset
                }
                suggestionsBoxOffset={hasPageHeader && !isInverted ? 64 : 0}
              />
            )}

            {hasLogin && (
              <SessionAddon
                isCollapsibleActive={
                  header.activeCollapsible === MAIN_HEADER_SESSION_COLLAPSIBLE
                }
                isHidden={shouldHideContent}
                isInverted={isInverted}
                onCollapsibleToggle={header.handleCollapsibleToggle}
              />
            )}

            {renderMainHeaderAddons &&
              renderMainHeaderAddons({
                isInverted,
                isTranslucent,
                mobileContentOffset,
                shouldHideContent,
              })}
          </>
        )}
        shouldBeInverted={isInverted}
        shouldBeSticky={!hasPageHeader && isSticky}
        shouldCollapseUntilXL={variant === 'eng'}
        shouldHaveBackground={header.mainHeaderShouldHaveBackground}
        shouldHideContent={
          header.activeCollapsible === MAIN_HEADER_SEARCH_COLLAPSIBLE
        }
      />

      {hasPageHeader &&
        renderPageHeader &&
        renderPageHeader({
          isCollapsibleActive:
            header.activeCollapsible === PAGE_HEADER_COLLAPSIBLE,
          isFullWidth,
          onCollapsibleToggle: header.handleCollapsibleToggle,
          ref: pageHeaderRef,
          shouldBeInverted: isInverted,
          shouldBeSticky: isSticky,
          shouldHaveBackground: header.pageHeaderShouldHaveBackground,
        })}
    </header>
  );
};

export default NewHeader;
