import { currentProjectName } from '@/config';
import { useState } from 'react';
import { BreakPoint } from '../useBreakPoint/consts';
import useIsMobile from '../useIsMobile/useIsMobile';
import type { ClickOutsideHandler } from '../useOnClickOutside/useOnClickOutside';
import useOnClickOutside from '../useOnClickOutside/useOnClickOutside';
import usePathname from '../usePathname/usePathname';
import {
  HEADER_AUDIENCES,
  HEADER_MAINHEADER_ITEMS,
  HEADER_MAINHEADER_LINKS,
  HEADER_PREHEADER_LINKS,
} from './config';
import {
  COLLAPSIBLE_OPTIONS,
  COLLAPSIBLE_TRANSITIONS,
  DEFAULT_OPTIONS,
  DEFAULT_TRANSITION,
  PREVENT_DISMISS_ATTRIBUTE,
} from './consts';
import { GetIsActiveFunction, UseHeader, UseHeaderOptions } from './types';
import { isMainHeaderMenuItem } from './utils';

const getTransition = (from: symbol | null, to: symbol) =>
  (from && COLLAPSIBLE_TRANSITIONS[from]?.[to]) ?? DEFAULT_TRANSITION;

const setIsActive = <
  T extends {
    getIsActive?: GetIsActiveFunction;
    isActive?: boolean;
    url: string;
  }
>(
  items: T[],
  pathname: string
) => {
  items.forEach((item) => {
    const { getIsActive = (p) => p.startsWith(item.url) } = item;
    item.isActive = getIsActive(pathname);
  });
};

const useHeader = ({
  clickOutsideRef,
  variant = currentProjectName,
}: UseHeaderOptions): UseHeader => {
  const isMobile = useIsMobile(BreakPoint.LG);
  const pathname = usePathname();

  const [activeCollapsible, setActiveCollapsible] = useState<symbol | null>(
    null
  );

  const [shouldHaveBackground, setShouldHaveBackground] = useState({
    mainHeader: false,
    pageHeader: false,
  });

  const audiences = HEADER_AUDIENCES[variant];
  const mainHeaderItems = HEADER_MAINHEADER_ITEMS[variant];
  const mainHeaderLinks = HEADER_MAINHEADER_LINKS[variant];
  const preHeaderLinks = HEADER_PREHEADER_LINKS[variant];

  const defaultAudience = audiences.find(
    (audience) => audience.isDefaultActive
  );

  setIsActive(audiences, pathname);
  setIsActive(mainHeaderLinks, pathname);

  mainHeaderItems
    .filter(isMainHeaderMenuItem)
    .forEach((item) => setIsActive(item.links, pathname));

  if (
    !audiences.some((audience) => audience.isActive === true) &&
    defaultAudience
  ) {
    defaultAudience.isActive = true;
  }

  const handleCollapsibleToggle = (collapsible: symbol) => {
    const {
      duration = 0,
      needsMainHeaderBackground = false,
      needsPageHeaderBackground = false,
    } = (COLLAPSIBLE_OPTIONS[collapsible] ?? DEFAULT_OPTIONS)({
      isMobile,
    });

    if (activeCollapsible === collapsible) {
      setActiveCollapsible(null);
      shouldHaveBackground &&
        setTimeout(
          () =>
            setShouldHaveBackground({
              mainHeader: false,
              pageHeader: false,
            }),
          duration
        );

      return;
    }

    if (!activeCollapsible || !(activeCollapsible in COLLAPSIBLE_TRANSITIONS)) {
      setActiveCollapsible(collapsible);
      setShouldHaveBackground({
        mainHeader: needsMainHeaderBackground,
        pageHeader: needsPageHeaderBackground,
      });

      return;
    }

    const { delay = 0 } = getTransition(
      activeCollapsible,
      collapsible
    )({ isMobile });

    delay > 0 && setActiveCollapsible(null);

    setTimeout(() => {
      setActiveCollapsible(collapsible);
      setShouldHaveBackground({
        mainHeader: needsMainHeaderBackground,
        pageHeader: needsPageHeaderBackground,
      });
    }, delay);
  };

  const handleOutsideClick: ClickOutsideHandler = (event) => {
    if (!event.target || !activeCollapsible) {
      return;
    }

    const el = event.target as HTMLElement;
    const targetPreventsDismiss =
      el.closest(`[${PREVENT_DISMISS_ATTRIBUTE}]`) !== null;

    if (targetPreventsDismiss) {
      return;
    }

    handleCollapsibleToggle(activeCollapsible);
  };

  useOnClickOutside(clickOutsideRef, handleOutsideClick);

  return {
    activeCollapsible,
    audiences,
    handleCollapsibleToggle,
    mainHeaderItems,
    mainHeaderLinks,
    mainHeaderShouldHaveBackground: shouldHaveBackground.mainHeader,
    pageHeaderShouldHaveBackground: shouldHaveBackground.pageHeader,
    preHeaderLinks,
  };
};

export default useHeader;
