export enum AnimatedIconVariant {
  AlarmSensor = 'alarm-sensor',
  AnnualReport = 'annual-report',
  Calculator = 'calculator',
  Calendar = 'calendar',
  ChargingStation = 'charging-station',
  ChargingStationInverse = 'charging-station-inverse',
  City = 'city',
  Coin = 'coin',
  Coin50 = 'coin-50',
  Company = 'company',
  ContactPerson = 'contact-person',
  ContractAmendents = 'contract-amendents',
  Contracting = 'contracting',
  DangerElectricity = 'danger-electricity',
  Diamond = 'diamond',
  Efficiency = 'efficiency',
  ElectricMeter = 'electric-meter',
  EnercityE = 'enercity-e',
  EnergyGasCircle = 'energy-gas-circle',
  Faq = 'faq',
  Fire = 'fire',
  GlassFiber = 'glass-fiber',
  GlassFiberSurrogate = 'glass-fiber-surrogate',
  Handshake = 'handshake',
  Heat = 'heat',
  Home = 'home',
  HouseConnection = 'house-connection',
  IBAN = 'iban',
  Infothek = 'infothek',
  Keys = 'keys',
  LightBulb = 'light-bulb',
  Lightning = 'lightning',
  Lock = 'lock',
  MovingTruck = 'moving-truck',
  None = 'none',
  OilHeating = 'oil-heating',
  Person = 'person',
  Phone = 'phone',
  Plug = 'plug',
  Press = 'press',
  Profit = 'profit',
  RFID = 'rfid',
  Rocket = 'rocket',
  Search = 'search',
  Smarthome = 'smarthome',
  Solar = 'solar',
  SolarPanel = 'solar-panel',
  StreetLight = 'street-light',
  SurFon = 'surf-fon',
  Tools = 'tools',
  Training = 'training',
  TreeTrunk = 'tree-trunk',
  WaterDrop = 'water-drop',
  WindPower = 'wind-power',
  Worldwide = 'worldwide',

  // small variants

  ColdWaterSmall = 'cold-water-small',
  WarmWaterSmall = 'warm-water-small',
  EnergyFireSmall = 'energy-fire-small',
  HeatSmall = 'heat-small',
  LightningSmall = 'lightning-small',
  StreetLightSmall = 'street-light-small',
}
